import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://2991eab0e25663b08767afe886601edf@o4507110525763584.ingest.us.sentry.io/4507368817754112',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // Sample rate at 10%
  replaysOnErrorSampleRate: 1.0, // Sample sessions with errors at 100%
  // Add beforeSend to filter events and disable the feedback dialog
  beforeSend(event, hint) {
    // Disable the user feedback dialog
    if (hint.originalException && typeof hint.originalException === 'object') {
      const exception = hint.originalException as { reported?: boolean };
      if (!exception.reported) {
        exception.reported = true;
        return null;
      }
    }
    return event;
  },
});

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
